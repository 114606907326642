<template>
  <!--pages/manager/match/plan.wxml-->
  <div class="manager-plan">
    <div class="header">
      <div class="title">还款总额</div>
      <div class="cont">
        {{ infos.totalPay }}
      </div>
      <div class="card">
        <div class="group">
          <div class="item">
            <div class="title">贷款总额</div>
            <div class="num bold">{{ infos.loan }}</div>
          </div>
          <div class="item">
            <div class="title">利息总额</div>
            <div class="num bold">{{ infos.totalInterest }}</div>
          </div>
        </div>
        <div class="group">
          <div class="item">
            <div class="title">还款月数</div>
            <div class="num">{{ list.length }}个月</div>
          </div>
          <div class="item">
            <div v-if="infos.payIndex == 0" class="title">每月月供</div>
            <div v-else class="title">每月本金</div>
            <div class="num">{{ infos.monthPay }}元</div>
          </div>
          <div class="item">
            <div class="title">利率</div>
            <div class="num">{{ infos.interestRateTo }}%</div>
          </div>
        </div>
      </div>
    </div>
    <div class="content">
      <div class="table">
        <div class="des">*以下数据仅供参考</div>
        <div class="thead">
          <div class="tr">
            <div class="td">期次</div>
            <div class="td">偿还利息</div>
            <div class="td">偿还本金</div>
            <div class="td">还款额</div>
            <div class="td">剩余本金</div>
          </div>
        </div>
        <div class="tbody">
          <div v-for="item in list" :key="item.num" class="tr">
            <div class="td">{{ item.num }}期/共{{ list.length }}期</div>
            <div class="td">{{ item.monthInterest }}</div>
            <div class="td">{{ item.monthMoney }}</div>
            <div class="td">{{ item.monthPay }}</div>
            <div class="td">{{ item.lastMoney }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import { getLocalUserInfo } from '@/utils/auth'
export default {
  data() {
    return {
      list: [],
      infos: {}
    }
  },
  mounted() {
    const { planList } = getLocalUserInfo()

    if (!planList) return this.$router.replace({ name: 'managerMatch' })
    this.list = planList
    this.infos = planList[0]
  }
}
</script>

<style lang="scss" scoped>
.manager-plan {
  min-height: 100%;
  background: #fcfcfc;
  .header {
    padding: 32px 0 18px 0;
    text-align: center;
    .title {
      font-size: 12px;
      color: #666666;
    }
    .cont {
      padding: 5px;
      font-size: 24px;
      color: #FF7226;
    }
    .card {
      width: 345px;
      height: 202px;
      padding-top: 9px;
      margin: 0 15px;
      box-sizing: border-box;
      background-size: 100%;
      background-image: url('~@/assets/match/card-plan@2x.png');
      .group {
        display: flex;
        padding-top: 29px;
        .item {
          flex: 1;
          position: relative;
          &::after{
            content: ' ';
            position: absolute;
            right: 0;
            top: 50%;
            margin-top: -15px;
            width: 1px;
            height: 30px;
            background: #F3F3F5;
          }
          &:last-child::after{
            display: none;
          }
          .title {
            font-size: 12px;
            color: #666666;
          }
          .num {
            padding-top: 10px;
            font-size: 16px;
            color: #2E3135;
          }
          .bold {
            font-weight: bold;
          }
        }
      }
    }
  }
  .content {
    .table {
      font-size: 12px;
      text-align: center;
      .des {
        padding-right: 16px;
        padding-bottom: 7px;
        text-align: right;
        color: #FF7700;
      }
      .thead {
        font-size: 14px;
        color: #91A0B4;
        background: #F5F5F7;
      }
      .tbody {
        background: #fff;
      }
      .tr {
        display: flex;
        border-bottom: 1px solid #F3F3F5;
        &:last-child {
          border: 0;
        }
        .td {
          font-size: 10px;
          width: 20%;
          padding: 12px 0;
          word-break:break-all;
        }
      }
    }
  }
}

</style>
